import React from 'react';
import { withNamespaces } from 'react-i18next';

class Louis extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Collège Saint-Louis</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 55</p>
                                    <p>{this.props.t("Number of students")} 924</p>
                                    <p>{this.props.t("International students")} 0.1%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 2013</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/louis1.jpg" alt="city" />
                                <img src="/images/louis2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("IBO (International Baccalaureate Program), Enriched English, French, Arts, Geography, Biology and Math.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, Dance, Volleyball, Basketball Teams.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Amnesty International, Newspaper, Graduation Committee, Science Club, Dance Troup, Community Service, Organized Trips Abroad.")}</span>
                                </div>
       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Louis);