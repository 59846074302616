import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

class Homestay extends React.Component {
  render() {
    return (
      <div className="homePageContainer">
        <div className="homeText">
          <div className="homeTextInnerWrapper">
            <div className="homeTextInner">
            <img src="/images/homestay.jpg" className="homestayPic" alt="city" />
              <div className="homestayInfoBlockWrapper">
                <h2>{this.props.t("English and French Host Families")}</h2>
                <div>{this.props.t("International students who need accommodation will be placed in a home within reasonable distance of their school. Placements will be with English speaking or French speaking families to provide stability and depth to students' cultural education and to support their English or French Language studies.")}</div>
                <br />
                <div>{this.props.t("The individual needs of the students and their preferences for hobbies, sports and other activities will be considered when placing students and families together. The current homestay fee covers the students room, reasonable access to the home, and three meals a day. Students are responsible for their own personal entertainment costs and personal supplies.")}</div>
                <br />
                
                <a href="https://vimeo.com/286684570" rel="noopener noreferrer" className="galleryButtonHome" target="_blank">{this.props.t("Play video")}</a>
                <Link to="/gallery" className="galleryButtonHome">{this.props.t("Go to gallery")}</Link>
              </div>
              
            </div>
          </div>
        </div>

         <div className="customItemListWrapper">
                    <div className="customItemListWrapperInner">
                        <ul className="customItemListStay">
                            <li>
                                <img src="/images/flag-slanted.svg" className="montrealPic" alt="city" />
                                <div>{this.props.t("Warm, caring families carefully chosen to host your children.")}</div>
                            </li>
                            <li>
                                <img src="/images/eye-circle.svg" className="montrealPic" alt="city" />
                                <div>{this.props.t("Professional staff supervising the homestay program.")}</div>
                            </li>
                            <li>
                                <img src="/images/phone-outline.svg" className="montrealPic" alt="city" />
                                <div>{this.props.t("Students will be called regularly at homestay and met at school.")}</div>
                            </li>
                            <li>
                                <img src="/images/document-rolled.svg" className="montrealPic" alt="city" />
                                <div>{this.props.t("Parents can receive written reports on their children – upon request.")}</div>
                            </li>
                
                        </ul>
                    </div>
                </div>
      </div>
    );
  }
}

export default withNamespaces()(Homestay);