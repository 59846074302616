import React from 'react';
import { withNamespaces } from 'react-i18next';

class Bruno extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>École secondaire du Mont-Bruno</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 95</p>
                                    <p>{this.props.t("Number of students")} 1600</p>
                                    <p>{this.props.t("International students")} 0.5%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Black polo top")} </p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")} </p>
                                    <p>{this.props.t("Founded")} 1988</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/bruno2.jpg" alt="city" />
                                <img src="/images/bruno1.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular academic, Leadership courses, Outdoor education, Multi media, photography, Theatre and Sports-study program")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, Curling, Basketball, Flag football, Volleyball, Ski , Cheerleading, Intramural sports, etc")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Robotics, Entrepreneur club, Theatre, Hip-Hop club, Expo-science, student council, Hockey team, Grad Ball committee, Art club etc.")}</span>
                                </div>
 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Bruno);