import React from 'react';
import { withNamespaces } from 'react-i18next';

class Lambert extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Saint-Lambert International High School</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 32</p>
                                    <p>{this.props.t("Number of students")} 400</p>
                                    <p>{this.props.t("International students")} 5%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1960</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/lambert2.jpg" alt="city" />
                                <img src="/images/lambert1.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Enriched French, Math, Science, Drama, Art and music")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, cross-country running, field hockey volleyball, badminton, golf, volleyball, curling, track, intramural sports, etc.Note 40% of students play on a sports team.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Drama club, community fundraising, school dances, yearbook, fashion show, public speaking math contest, student council, Ski trips, trip to New York")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Lambert);