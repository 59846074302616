import React from 'react';
import { withNamespaces } from 'react-i18next';

class Rosemere extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Rosemere High School</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 65</p>
                                    <p>{this.props.t("Number of students")} 1200</p>
                                    <p>{this.props.t("International students")} 0.5%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1977</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/rosemere2.jpg" alt="city" />
                                <img src="/images/rosemere1.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Intensive English and Math, Leadership Program, Instrumental Music, Comprehensive Art Programs.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Track and Field, Basketball, Soccer, Cross Country Running, Intramural Sports, Etc.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Carnival Dances, Variety Show, Hip Hop, Newspaper, Yearbook, Robotics, Honor Roll Club, School Trips, Community Involvement, Science Fair.")}</span>
                                </div>
         
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Rosemere);