import React from 'react';
import { withNamespaces } from 'react-i18next';

class Heritage extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Heritage Regional High School</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 95</p>
                                    <p>{this.props.t("Number of students")} 1800</p>
                                    <p>{this.props.t("International students")} 3%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Dress code")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1975</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/heritage2.jpg" alt="city" />
                                <img src="/images/heritage1.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, International Baccalaureate Program, Intensive Math, Science, Drama Program, Inventions and Inventors Study.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Two Artificial Turf Fields, 5 Gyms, 2 Weight Rooms, Indoor Skating Rink and Swimming Pool, Basketball, Hockey, Swimming, Etc.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Theater Club, Public Speaking, Photo Club, Student Council, Chess, Yearbook Club, Calculus Club.")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Heritage);