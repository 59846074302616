import React from 'react';
import { withNamespaces } from 'react-i18next';

class Laval extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Laval Senior Academy</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 29</p>
                                    <p>{this.props.t("Number of students")} 550</p>
                                    <p>{this.props.t("International students")} 1%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("9-11-1")}</p>
                                    <p>{this.props.t("Founded")} 1973</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/liberty1.jpg" alt="city" />
                                <img src="/images/liberty2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Sports Specialty Football, Soccer, Art Specialty, Enriched Math, French and English, Dance, Theater, Creative Arts.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Competitive: Basketball, Soccer, Football, Rugby Teams, etc.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Variety Show, Robotics Club, Gamers Club, Multicultural Club, Web Site Development, Community Involvement, Science Fair, Graduation Commitee, School band, Student Council etc.")}</span>
                                </div>
     
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Laval);