import React from 'react';
import { withNamespaces } from 'react-i18next';

class Letendre extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Collège Letendre</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 80</p>
                                    <p>{this.props.t("Number of students")} 1530</p>
                                    <p>{this.props.t("International students")} 0.1%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")} </p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1976</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/letendre1.jpg" alt="city" />
                                <img src="/images/letendre2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular academic, Enriched English, Physics, Chemistry, Biology, Drama, Arts, Civil Law, Journalism, Dance, Music (wind instruments), Spanish")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, Scuba Diving, Basketball, Football, Cheerleading, Volleyball, Self Defense, Camping, Athletics, etc")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Theatre group, Jazz & Rock Band, Orchestra, Improv group, Newspaper, Photography, Student council, Trips to New York, Boston and more!")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Letendre);