import React from 'react';
import { withNamespaces } from 'react-i18next';

class Edmond extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>École internationale Saint-Edmond</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 40</p>
                                    <p>{this.props.t("Number of students")} 700</p>
                                    <p>{this.props.t("International students")} 0%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1968</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/edmond1.jpg" alt="city" />
                                <img src="/images/edmond2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("IBO (International Baccalaureate Program), Enriched English, French, Arts, Geography , Biology, Enriched Math, Music or Art Option.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Basketball, Soccer, Volleyball.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Amnesty International, Student Council, Improv Troupe, Organized trips to USA, Music Orchestras – 4 bands with wind instruments (2/3 of school are part of orchestras), won many music awards.")}</span>
                                </div>
                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Edmond);