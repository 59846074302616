import React from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from '../i18n';

const Register = ({t}) => (
  <div className="homePageContainer">

  <div className="homeText">
    <div className="homeTextInnerWrapper">
      <div className="homeTextInner">
        <div className="registerWrapperInfo">
          <h2>{t("How to register")}</h2>
          <p>{t("The simplest way. Contact your SEIQ partner agency to learn more")}</p>
          <p>{t("OR")}</p>
          <p>{t("Follow steps below")}</p>

          <h3>{t("Step 1")}</h3>
          

          {i18n.language ==="en" ?<p>Complete the International Student On-Line Application <a href="/docs/6.2-Student HighS Eng. Regist. Form.pdf" download className="clickHere">Form Click</a> Here to register on-line.</p> :
            <p>Veuillez <a href="/docs/6.2-Student HighS Eng. Regist. Form.pdf" download className="clickHere">cliquer ici</a> pour remplir en ligne les Formules d’inscription de l’étudiant international.</p>
          }

          <h3>{t("Step 2")}</h3>
          <p>{t("Submit your completed International Student Application Form, along with the following")}</p>
          <p>{t("Copy of latest original transcripts (school reports), also translated into English. Translations must be signed and stamped. Photocopies must be certified with the issuing school's stamp. Introduction letter to host family (in English or French). Copy of your original birth certificate, also translated into English. Translations must be signed and stamped. Photocopies must be certified with a stamp.")}</p>

          <h3>{t("Step 3")}</h3>
          <p>{t("Send step 2 documents via email to Mr. Polan at bpolan@seiq.com")}</p>

          <h3>{t("Step 4")}</h3>
          <p>{t("SEIQ will review your student application and official transcripts to determine acceptance, and then send you a list of final documents required for a Student Study Permit.")}</p>
          <p>{t("If you are accepted into our programs, an invoice for applicable student fees emailed to you or your representative. Upon receiving full payment of student fees, the official Letter of Acceptance and Notarized Custodian document (if requested) will be couriered to you or your representative.")}</p>
          <p>{t("Note There is a $200 non-refundable Application Fee (Cheque payable to SEIQ)")}</p>

          <p>{t("For Cancellation and Refund Policy,")} 
            {i18n.language ==="en" ?<a href="/docs/6.1 Cancellation and Refund Policy-EN.pdf" download className="clickHere"> {t("please click here.")}</a> :
            <a href="/docs/6.1 Cancellation and Refund Policy-FR.pdf" download className="clickHere"> {t("please click here.")}</a>
          }
          </p>

          
        </div>
        <img src="/images/cutGuyForLyndonWebsite.png" className="registrationPic" alt="city" />
        <div>
        <a href="/docs/6.2-Student Accommodation FORM-EN+FR.pdf" download className="galleryButton">Student Accommodation Form</a>
          <a href="/docs/6.2-Student HighS Eng. Regist. Form.pdf" download className="galleryButton">Student HighS Eng. Regist. Form</a>
          <a href="/docs/6.2-Student HighS Fre. Regist. Form.pdf" download className="galleryButton">Student HighS Fre. Regist. Form</a>
        </div>
        
      </div>
    </div>
  </div>
</div>
  );

export default withNamespaces()(Register);