import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'normalize.css/normalize.css';
import './styles/styles.css';
import  Montreal  from './components/Montreal';
import  Register from './components/Register';
import  Study  from './components/Study';
import  Schools  from './components/Schools';
import  Homestay  from './components/Homestay';
import { NotFoundPage } from './components/NotFoundPage';
import Gallery from './components/Gallery';
import GalleryTwo from './components/GalleryTwo';
import About from './components/About';
import Links from './components/Links';
import PlayerModule from './components/Player';
import Labelle from './components/schoolsComponents/Labelle';
import Laporte from './components/schoolsComponents/Laporte';
import Internationale from './components/schoolsComponents/Internationale';
import Prelude from './components/schoolsComponents/Prelude';
import Trois from './components/schoolsComponents/Trois';
import Bruno from './components/schoolsComponents/Bruno';
import Louis from './components/schoolsComponents/Louis';
import Edmond from './components/schoolsComponents/Edmond';
import Heritage from './components/schoolsComponents/Heritage';
import Lambert from './components/schoolsComponents/Lambert';
import Centennial from './components/schoolsComponents/Centennial';
import Westmount from './components/schoolsComponents/Westmount';
import Lester from './components/schoolsComponents/Lester';
import Lauren from './components/schoolsComponents/Lauren';
import Laval from './components/schoolsComponents/Laval';
import Rosemere from './components/schoolsComponents/Rosemere';
import Anne from './components/schoolsComponents/Anne';
import Regina from './components/schoolsComponents/Regina';
import Citoyen from './components/schoolsComponents/Citoyen';
import Letendre from './components/schoolsComponents/Letendre';
import Header from './Header';
import Footer from './Footer';
import './i18n';

ReactDOM.render(
  <BrowserRouter>
  <div>

  

    <Header />

    <PlayerModule />
    <Switch>
      <Route path="/" component={Study} exact={true} />
      <Route path="/montreal" component={Montreal} />
      <Route path="/homestay" component={Homestay} />
      <Route path="/schools" component={Schools} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/gallery2" component={GalleryTwo} />
      <Route path="/about" component={About} />
      <Route path="/register" component={Register} />
      <Route path="/labelle" component={Labelle} />
      <Route path="/laporte" component={Laporte} />
      <Route path="/internationale" component={Internationale} />
      <Route path="/prelude" component={Prelude} />
      <Route path="/trois" component={Trois} />
      <Route path="/bruno" component={Bruno} />
      <Route path="/louis" component={Louis} />
      <Route path="/edmond" component={Edmond} />
      <Route path="/heritage" component={Heritage} />
      <Route path="/stLambert" component={Lambert} />
      <Route path="/centennial" component={Centennial} />
      <Route path="/westmount" component={Westmount} />
      <Route path="/lester" component={Lester} />
      <Route path="/lauren" component={Lauren} />
      <Route path="/laval" component={Laval} />
      <Route path="/rosemere" component={Rosemere} />
      <Route path="/anne" component={Anne} />
      <Route path="/regina" component={Regina} />
      <Route path="/citoyen" component={Citoyen} />
      <Route path="/letendre" component={Letendre} />
      <Route path="/links" component={Links} />
      <Route component={NotFoundPage} />
    </Switch>
    <Footer />

  </div>
</BrowserRouter>

  , document.getElementById('root'));
