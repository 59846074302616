import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

class Schools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      frPrivSchools: false,
      frSchools: false,
      enSchools: false,
    };
  }

  componentWillMount() {
    setTimeout(() => this.setState({ frSchools: true, }), 50)
  }

  frenchSchoolsHandler = () => {
    this.setState({
      frPrivSchools: false,
      frSchools: !this.state.frSchools,
      enSchools: false,
    })
  }

  frenchPrivSchoolsHandler = () => {
    this.setState({
      frPrivSchools: !this.state.frPrivSchools,
      frSchools: false,
      enSchools: false,
    })
  }


  englishSchoolsHandler = () => {
    this.setState({
      frPrivSchools: false,
      frSchools: false,
      enSchools: !this.state.enSchools
    })
  }

  render() {
    return (
      <div className="homePageContainer">
        <div className="homeText">
          <div className="homeTextInnerWrapper">
            <div className="homeTextInner">
              <div className="schoolsCustomButtonsWrapper">
                <div className="SchoolsCumstomButton" onClick={this.frenchSchoolsHandler}>{this.props.t("French Schools")}</div>
                <div className="SchoolsCumstomButton" onClick={this.englishSchoolsHandler}>{this.props.t("English Schools")}</div>
                <div className="SchoolsCumstomButton" onClick={this.frenchPrivSchoolsHandler}>{this.props.t("Private French Schools")}</div>
              </div>

              <div className="SchoolsBlockWrapper">

                <div className="frenchSchoolsWrapper">
                  <div style={this.state.frSchools ? { marginTop: '30px', opacity: '1' } : { marginTop: '-650px', opacity: '0' }}>
                    <Link to="/labelle" className="antoineLabelle">École secondaire Curé-Antoine-Labelle</Link>
                    <Link to="/laporte" className="pierre">École secondaire Pierre-Laporte</Link>
                    <Link to="/internationale" className="internationale">École d’éducation Internationale de Laval</Link>
                    <Link to="/prelude" className="prelude">École secondaire le Prélude</Link>
                    <Link to="/trois" className="trois">École secondaire des Trois-Saisons</Link>
                    <Link to="/bruno" className="bruno">École secondaire du Mont-Bruno</Link>
                    <Link to="/louis" className="louis">Collège Saint-Louis</Link>
                    <Link to="/edmond" className="edmond">École internationale Saint-Edmond</Link>
                  </div>
                </div>

                <div className="englishSchoolsWrapper">
                  <div style={this.state.enSchools ? { marginTop: '30px', opacity: '1' } : { marginTop: '-650px', opacity: '0' }}>
                    <Link to="/heritage" className="heritage">{this.props.t("Heritage Regional High School")}</Link>
                    <Link to="/stLambert" className="stLambert">{this.props.t("St Lambert International High School")}</Link>
                    <Link to="/centennial" className="centennial">{this.props.t("Centennial Regional High School")}</Link>
                    <Link to="/westmount" className="westmount">{this.props.t("Westmount High School")}</Link>
                    <Link to="/lester" className="lester">{this.props.t("Lester B Pearson High School")}</Link>
                    <Link to="/lauren" className="lauren">{this.props.t("Lauren Hill Academy")}</Link>
                    <Link to="/laval" className="laval">{this.props.t("Laval Senior Academy")}</Link>
                    <Link to="/rosemere" className="rosemere">{this.props.t("Rosemere High School")}</Link>
                  </div>
                </div>

                <div className="frenchPrivSchoolsWrapper">
                  <div style={this.state.frPrivSchools ? { marginTop: '30px', opacity: '1' } : { marginTop: '-650px', opacity: '0' }}>
                    <Link to="/anne" className="anne">Collège Sainte-Anne</Link>
                    <Link to="/regina" className="regina">Collège Regina Assumpta</Link>
                    <Link to="/citoyen" className="citroyen">Collège Citoyen</Link>
                    <Link to="/letendre" className="letendre">Collège Letendre</Link>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Schools);