import React from 'react';
import { withNamespaces } from 'react-i18next';

class Westmount extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Westmount High School</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 33</p>
                                    <p>{this.props.t("Number of students")} 550</p>
                                    <p>{this.props.t("International students")} 8%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1967</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/westmount2.jpg" alt="city" />
                                <img src="/images/westmount1.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Enriched Math, English, Computer Science, Business Education, Wood Work, Sports Leadership.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Track and Field, Basketball, Badminton, Soccer, Softball, Hockey, Soccer.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Computer Club, Chess Club, Dance, Jazz Band, Drama, Student Radio, Outdoor Education, Trips, Etc.")}</span>
                                </div>
                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Westmount);