import React from 'react';
import { Player, ControlBar, VolumeMenuButton, BigPlayButton } from 'video-react';

class PlayerModule extends React.Component {

  //window.location.href
  //height={800}

  // for no off
  /*
  test = () => {
    console.log(window.location)
    return window.location.href !== "http://localhost:8080/"? "/video/video500.mp4" : "/video/video800.mp4";
  }
  */

  render(){
    return (
      <div className="homePageContainer">
        <div className="innerWrapper">
          <div className="playerContainer">
            <Player
              fluid={true}
              height={800}
              width={1920}
              autoPlay
              loop
              muted={true}
              poster=""
              src={"/video/video500.mp4"}
            >
              <ControlBar autoHide={false} disableDefaultControls>
                <VolumeMenuButton vertical />
              </ControlBar>
              <BigPlayButton position="center" />
            </Player>
          </div>
        </div>
    </div>
    );
  }
}

  export default PlayerModule;