import React from 'react';
//import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
//if component <h1>{this.props.t('lang')}</h1>

function Study({ t }) {

    return (
        <div className="homePageContainer">

            <div className="homeText">
                <div className="homeTextInnerWrapper">
                    <div className="homeTextInner">
                        <div className="montrealInfoBlock">
                            <h2>{t("English and French schools – Twice the education opportunity")}</h2>

                            <div>{t("Study/ Études In Québec (SEIQ) offers international students the possibility to study in English or French with the opportunity of enjoying the hospitality of either an English or French host family.")}</div>
                            <br />
                            <div>{t("For over a decade, SEIQ has been offering this unique academic experience to International High school students from around the world. SEIQ will take care to find a quality school and caring host family for students, take care of full medical coverage and visit students regularly to ensure that they enjoy their International education experience.")}</div>
                            <br />
                            <div>{t("Your unique educational experience is just a step away.")}</div>
                            <br />

                            <div>{t("SEIQ (Study/Études In Québec) is in partnership with:")}</div>
                            <ul>
                                <li>{t("8 English high schools")}</li>
                                <li>{t("8 French high schools")}</li>
                                <li>{t("4 French private high schools")}</li>
                            </ul>
                            
                            <a href="https://vimeo.com/286707766?fbclid=IwAR3Vuv1mh9Dm35X6IQBn4z8cWGSO8cEbA1o3cfHiGsBAZXGt7q8dufDwLYo" rel="noopener noreferrer" target="_blank" className="galleryButton">{t("Portuguese")}</a>
                            <a href="https://vimeo.com/466303800?fbclid=IwAR2qOEeiftoHp8Mp4a6zktmU2JDMkaCGWHNMtRYB7fK53wltHpxk0t4llvQ" rel="noopener noreferrer" target="_blank" className="galleryButton">{t("German")}</a>
                            <a href="https://vimeo.com/469525507?fbclid=IwAR2LiWH0lbiOlVT7FRGM7ScGB7PhYSsVXmbGVLo6imm-4u2vZ_GA6bchPIM" rel="noopener noreferrer" target="_blank" className="galleryButton">{t("French")}</a>

                        </div>
                        <img src="/images/montreal5.jpg" id="testPic" className="montrealPic" alt="city" />
                    </div>
                </div>
            </div>

            <div className="customItemListWrapper">
                <div className="customItemListWrapperInner">
                    <ul className="customItemList">
                        <li>
                            <img src="/images/flag-slanted.svg" className="montrealPic" alt="city" />
                            <div>{t("High Academic standards")}</div>
                        </li>
                        <li>
                            <img src="/images/star-circle.svg" className="montrealPic" alt="city" />
                            <div>{t("High quality programs")}</div>
                        </li>
                        <li>
                            <img src="/images/pie-chart.svg" className="montrealPic" alt="city" />
                            <div>{t("International student population of less than 2%")}</div>
                        </li>
                        <li>
                            <img src="/images/lightbulb.svg" className="montrealPic" alt="city" />
                            <div>{t("Grade 7 to 11 in Quebec high schools")}</div>
                        </li>

                    </ul>
                </div>
            </div>

        </div>
    );
}


export default withNamespaces()(Study);
// export default Study;