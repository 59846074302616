import React from 'react';
import { withNamespaces } from 'react-i18next';

const Links = ({t}) => (

      <div className="homePageContainer">

        <div className="homeText">
          <div className="homeTextInnerWrapper">
            <div className="homeTextInner">
              <div className="montrealInfoBlock">
                
                <h2>SEIQ – {t("LINKS")}</h2>

                <h3>To-Do in MONTREAL</h3>
                <div><b>MTL BLOG</b></div> 
                <div>From restaurants, shopping, etc …to do in the city</div>
                <div><a href="https://www.mtlblog.com/" target="_blank" rel="noopener noreferrer" className="clickHere">https://www.mtlblog.com</a></div>

                <br></br>

                <div><b>VOIR</b></div> 
                <div>In FRENCH concerts – museums - culture</div>
                <div>VOIR – click on MONTREAL</div>
                <div><a href="https://voir.ca/" target="_blank" rel="noopener noreferrer" className="clickHere">voir.ca</a></div>
               

                <h3>TRAVEL</h3>
                <div><a href="http://www.taketours.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.taketours.com</a></div>
                <br></br>

                <div><b>New York – Boston</b></div>
                <div><a href="https://www.poltours.com/" target="_blank" rel="noopener noreferrer" className="clickHere">www.poltours.com</a></div>
                <br></br>

                <div><b>Toronto and Niagara Falls</b></div>
                <div><a href="https://www.wondertravels.ca/en/pages/tours" target="_blank" rel="noopener noreferrer" className="clickHere">www.wondertravels.ca/en/pages/tours</a></div>
                <br></br>

                <div><b>New York</b></div>
                <div><a href="http://www.frenzytours.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.frenzytours.com</a></div>
                <br></br>

                <div><b>TRAVEL - what to do seasonal in province of Quebec - Québec Original</b></div>
                <div><a href="bulletin@quebecoriginal.com" target="_blank" rel="noopener noreferrer" className="clickHere">bulletin@quebecoriginal.com</a></div>

                <h3>CONCERTS</h3>

                <div><a href="http://www.songkick.com/metro_areas/27377-canada-montreal" target="_blank" rel="noopener noreferrer" className="clickHere">www.songkick.com/metro_areas/27377-canada-montreal</a></div>
                <div><a href="http://popmontreal.com" target="_blank" rel="noopener noreferrer" className="clickHere">popmontreal.com</a></div>
                <div><a href="http://www.montrealenlumiere.com/nuit-blanche-en/" target="_blank" rel="noopener noreferrer" className="clickHere">www.montrealenlumiere.com/nuit-blanche-en</a></div>

                <h3>SKIING</h3>

                <div><a href="https://www.expresstours.ca/ski.php" target="_blank" rel="noopener noreferrer" className="clickHere">www.expresstours.ca/ski.php</a></div>
                <ul>
                    <li>go skiing at Mont Saint Sauveur, you can equally take Galland buses for 25$</li>
                    <li>ski trips to plenty of cool spots in Canada and the USA for reasonable prices</li>
                </ul>
                <div><a href="https://www.rodroy.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.rodroy.com</a></div>

                <h3>Rent – Buy skis</h3>

                <div>Poubelle Du Ski</div> 
                <div>8278 St Laurent Blvd  Montreal, QC H2P 2L8</div>
                <div>(514) 384-1315</div>
                <div><a href="https://www.poubelleduski.ca" target="_blank" rel="noopener noreferrer" className="clickHere">www.poubelleduski.ca</a></div>

                <h3>SPORTS</h3>

                <div><b>FIELD HOCKEY</b></div> 
                <div>Phone : 514-426-8405</div>
                <div><a href="http://www.ministryofcricket.ca/" target="_blank" rel="noopener noreferrer" className="clickHere">www.ministryofcricket.ca</a></div>
                <br></br>

                <div><b>HANDBALL</b></div> 
                <div>Handball-Quebec</div>
                <div>514-252-3067</div>
                <div><a href="http://www.handball.qc.ca" target="_blank" rel="noopener noreferrer" className="clickHere">www.handball.qc.ca</a></div>
                <br></br>

                <div><b>TENNIS</b></div> 
                <div>Tennis - Longueuil</div>
                <div>Tennis in the complexe sportif Longueuil,  550 Boulevard Curé-Poirier Ouest</div>
                <div>Longueuil.</div>
                <div>1-450-679-6131</div>
                <div><a href="http://complexesportiflongueuil.com/en/home" target="_blank" rel="noopener noreferrer" className="clickHere">www.complexesportiflongueuil.com/en/home</a></div>
                <br></br>

                <div><b>Tennis - Laval</b></div> 
                <div>Carrefour multisport</div>
                <div>3095 autoroute Laval</div>
                <div>1-450- 687-1857</div>
                <div><a href="https://carrefourmultisports.com/en/" target="_blank" rel="noopener noreferrer" className="clickHere">www.carrefourmultisports.com/en</a></div>
                <br></br>

                <div><b>Tennis 13</b></div> 
                <div>1013 autroute 13, Laval</div>
                <div>(450) 687-9913</div>
                <div><a href="http://tennis13.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.tennis13.com</a></div>
                <br></br>

                <div><b>VOLLEYBALL</b></div> 
                <div>South shore</div>
                <div><a href="http://www.lynxrivesud.com " target="_blank" rel="noopener noreferrer" className="clickHere">www.lynxrivesud.com </a></div>
                <div>Martin Carle</div> 
                <div>Président</div>
                <div>Lynx de la Rive-Sud</div> 
                <div>514-569-3432</div>
                <br></br>

                <div><b>VOLLEYBALL</b></div> 
                <div>Laval</div>
                <div><a href="http://www.volleylaval.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.volleylaval.com</a></div>
                <div>Address: 3235 Boul Saint-Martin Est, Laval, QC H7E 5G8</div> 
                <div>Phone: (450) 664-1917 ext. 302</div>
                <br></br>

                <div><b>TRACK N FIELD</b></div> 
                <div><a href="http://www.ministryofcricket.ca/" target="_blank" rel="noopener noreferrer" className="clickHere">www.ministryofcricket.ca</a></div>
                <br></br>

                <div>Claude Robillard Centre</div>
                <div>André Barriere abarriere58@gmail.com</div>
                <div>Sylvain Archambault  sarchambault33@videotron.ca</div>
                <br></br>

                <div><b>ICE SKATING</b></div>
                <div>Atrium le  1000 la Gauchetiere – 395-4846</div>
                <br></br>

                <div><b>TRAINING</b></div> 
                <div>Club Sportif MAA</div>
                <div><a href="http://www.clubsportifmaa.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.clubsportifmaa.com</a></div>
                <div>2070 Rue Peel, Montréal, QC H3A 1W6, Canada</div>
                <br></br>

                <div><b>ROWING</b></div> 
                <div>514-861-8959  3-8pm</div>
                <div>TRAINING</div> 
                <div><a href="http://www.avironmontreal.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.avironmontreal.com</a></div>

                <h3>DANCE + HIP HOP</h3>

                <div><a href="https://dansejocelyne.ca" target="_blank" rel="noopener noreferrer" className="clickHere">www.dansejocelyne.ca</a></div>
                <div>(450) 461-3847</div> 
                <div>1400 Montarville, Saint-Bruno, Québec</div>


                <div><a href="https://www.studiodansemontreal.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.studiodansemontreal.com</a></div>
                <div>Studio danse Montreal 514-223-3918  -- HIP HOP</div> 
                <div>7240 Clark, metro de Castelneau or Jean-Talon</div>
                <br></br>

                <div><a href="https://www.danseclaudepilon.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.danseclaudepilon.com</a></div>
                <div>LAVAL</div> 
                <div>école de danse Claude Pilon – hip hop</div>
                <div>42 Bd de la Concorde E  Laval, QC H7G 4Y1</div>
                <div>(450) 669-0869</div>
                <br></br>

                <div><a href="https://www.junior-ballet.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.junior-ballet.com</a></div>
                <div>Longueuil</div> 
                <div>600 boul curé poirier est, Longueuil</div>
                <div>450 -928-7055600</div>
                <br></br>

                <div>HIP HOP – Rockwell Family – South shore</div>
                <div><a href="https://rockwellfamily.ca" target="_blank" rel="noopener noreferrer" className="clickHere">www.rockwellfamily.ca</a></div>
                <div>STUDIO DE DANSE ROCKWELL FAMILY</div> 
                <div>329, Duvernay Beloeil, J3G 5S8</div>
                <div>Tel : 450.281.2828</div>
                <br></br>

                <div><a href="https://www.tripolistudios.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.tripolistudios.com</a></div>
                <div>Tripoli studios in Montreal. Her Facebook address is:</div> 
                <div><a href="https://m.facebook.com/aya.reneena" target="_blank" rel="noopener noreferrer" className="clickHere">m.facebook.com/aya.reneena</a></div>

                <h3>SOMETHING DIFFERENT</h3>

                <div><b>A-MAZE ESCAPE ROOM</b></div>
                <div><a href="https://www.amazemontreal.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.amazemontreal.com</a></div>
                <div>Address: 3550 Rue Saint-Jacques, Montreal, Quebec H4C 1H2, Canada</div> 
                <div>Phone Number: 514-303-1616</div>
                <br></br>

                <div><b>WALL CLIMBING</b></div>
                <div><a href="http://www.amusementactiondirecte.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.amusementactiondirecte.com</a></div>
                <div>Escalade Action direct – 1-450-688-0515</div> 
                <div>Climbing</div>
                <div><a href="http://www.actiondirect@qc.ca" target="_blank" rel="noopener noreferrer" className="clickHere">www.actiondirect@qc.ca</a></div>
                <br></br>

                <div><b>SIGN IN A CHOIR</b></div>
                <div><a href="https://www.choeurdesenfantsdemontreal.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.choeurdesenfantsdemontreal.com</a></div>
                <div>Andrea Cooper – SINGING CHORUS</div> 
                <div>Manager  Choeur des enfants de MontréaL</div>
                <div>450-458-7129</div>
                <br></br>

                <div><b>GOSPEL SINGING</b></div>
                <div><a href="http://jubilationchoir.com" target="_blank" rel="noopener noreferrer" className="clickHere">www.jubilationchoir.com</a></div>
                <br></br>

                <div><b>HISTORY OF MONTREAL</b></div>
                <div><a href="https://www.chateauramezay.qc.ca/en" target="_blank" rel="noopener noreferrer" className="clickHere">www.chateauramezay.qc.ca/en</a></div>
                <div>Chateau Ramezay – 861-3708  HISTORY OF MONTREAL</div>
                <br></br>

                <div><b>ACTING</b></div>
                <div><a href="http://www.ioactingstudio.com/Classes/" target="_blank" rel="noopener noreferrer" className="clickHere">www.ioactingstudio.com/Classes</a></div>

              </div>
              <img src="/images/links.jpg" id="testPic" className="montrealPic" alt="city" />
            </div>
          </div>
        </div>

      

      </div>


    );


export default withNamespaces()(Links);