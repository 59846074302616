import React from 'react';
import { withNamespaces } from 'react-i18next';

class Regina extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Collège Regina Assumpta</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 99</p>
                                    <p>{this.props.t("Number of students")} 2222</p>
                                    <p>{this.props.t("International students")} 0%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")} </p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1955</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/regina1.jpg" alt="city" />
                                <img src="/images/regina2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular academic, Dance concentration (ballet and modern) program, Music concentration program (4 full orchestras)")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("American Football , Golf, Girls Basketball, Fencing,  Soccer, Swimming, Ice hockey (boy, girl), Volleyball, Cheerleading, etc")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Climbing, Ski, Drama, Photography,  student council, media club, athletics, badminton, and so much more!")}</span>
                                </div>
                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Regina);