import React from 'react';
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import { NavLink } from 'react-router-dom';

function Header ({ t }) {
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    }

    const switchLngButton = () => {
        return (
            <React.Fragment>
            {
              i18n.language === "en" ? 
            <span onClick={() => { changeLanguage('fr') }} className="langSwitchFr" >FR</span> :
            <span onClick={() => { changeLanguage('en') }} className="langSwitchFr" >EN</span>
            }
            </React.Fragment> 
        )
    }
  
    return (
      <div>
  
        <div className="headerContainer">
            <div className="menu">        
              <div className="navigation">
                <NavLink to="/"  exact={true}>{t("Study")}</NavLink>
                <NavLink to="/schools" >{t("Schools")}</NavLink>
                <NavLink to="/montreal" >{t("Montreal")}</NavLink>
                <NavLink to="/homestay" >{t("Homestay")}</NavLink>
                <NavLink to="/about" >{t("About")}</NavLink>        
                <NavLink to="/register" >{t("Register")}</NavLink>

                {switchLngButton()}

                <NavLink to="/links" >{t("Links")}</NavLink>

              </div>
            </div>
          </div>

      </div>
    )
  }
  
  export default withNamespaces()(Header);