import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

const Montreal = ({t}) => (
  <div className="homePageContainer">

    <div className="homeText">
      <div className="homeTextInnerWrapper">
        <div className="homeTextInner">
          <div className="montrealInfoBlock">
            <h2>{t("European ﬂavour with American convenience")}</h2>
            <div>{t("Montreal is a cosmopolitan, bilingual city that is open to International high school age students who wish to study in either English or French. In fact, most of people in Montreal speak English and French. SEIQ has partnerships with English and French school boards as well as French private schools in the Montreal metropolitan region, giving international students a wide range of study possibilities.")}</div>
            <br />
            <div>{t("With over 4,500,000 (second largest city in Canada), Montreal is the only city in the world that offers international students the opportunity to study in English or French and the possibility to enjoy the hospitality of either an English or French host family!")}</div>
            <br />
            <div>{t("Finally, Montreal boasts European flavour with American convenience, making it a unique cultural and educational destination in North America.")}</div>
            <a href="https://vimeo.com/286684570?fbclid=IwAR3y1YmRfOoQTcQ6oPO6KBOX7tVDdz638JXxXdpMQPjUA4xyzSQLTS6lmzY" rel="noopener noreferrer" target="_blank" className="galleryButton">{t("Video")}</a>
            <Link to="/gallery" className="galleryButton">{t("Go to gallery")}</Link>
          </div>
          <img src="/images/montreal.jpg" className="montrealPic" alt="city" />
        </div>
      </div>
    </div>
  </div>
);

export default withNamespaces()(Montreal);