import React from 'react';
import { withNamespaces } from 'react-i18next';

class Internationale extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>École d’éducation Internationale de Laval</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 87</p>
                                    <p>{this.props.t("Number of students")} 1500</p>
                                    <p>{this.props.t("International students")} 0.5%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1980</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/Inter2.jpg" alt="city" />
                                <img src="/images/Inter.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Enriched French, Math, English, Science, Drama, Art and Media Classes, International Baccalaureate Program.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Basketball, Soccer, Volleyball, Badminton, 15 Sports Teams, Weight Room and Fitness Area.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Canoe Club, Ski Club, Harmony Choir, Student Council, Travel Club, Community Service.")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Internationale);