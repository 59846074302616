import React from 'react';
import { withNamespaces } from 'react-i18next';

class About extends React.Component {
  render() {
    return (
      <div className="homePageContainer">

        <div className="homeText">
          <div className="homeTextInnerWrapper">
            <div className="homeTextInner">
              <div className="montrealInfoBlock">
                <h2>{this.props.t("Discover SEIQ")}</h2>
                <div>{this.props.t("The mission of Study/Études In Québec (SEIQ) is to be your bridge with high schools in the Montreal area by giving international students of all ages the opportunity to study in an English or French high school for one year, 5 months or 3 months while benefiting from the safe and caring environment of an English–speaking or French-speaking homestay family in the Montreal metropolitan area.")}</div>
                <br />
                <div>{this.props.t("SEIQ provides marketing expertise, immigration knowledge, cultural understanding and international education program knowledge to assist your students in selecting the programs that suit them best.")}</div>
                <br />
                <div>{this.props.t("We believe that giving students the opportunity of learning English and French in an international atmosphere is key to their future success. Contact us to learn more.")}</div>

                <a href="https://vimeo.com/286684570?fbclid=IwAR3y1YmRfOoQTcQ6oPO6KBOX7tVDdz638JXxXdpMQPjUA4xyzSQLTS6lmzY" rel="noopener noreferrer" target="_blank" className="galleryButton">{this.props.t("Video")}</a>
              </div>
              <img src="/images/montreal4.jpg" id="testPic" className="montrealPic" alt="city" />
            </div>
          </div>
        </div>

        <div className="customItemListWrapper">
          <div className="customItemListWrapperInner">
            <ul className="customItemList">
              <li>
                <img src="/images/globe-outline.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("8 English and 12 French High Schools")}</div>
              </li>
              <li>
                <img src="/images/star-circle.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("Academic excellence")}</div>
              </li>
              <li>
                <img src="/images/sofa-chair.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("Quality English and French homestays")}</div>
              </li>
              <li>
                <img src="/images/weather-umbrella-dropletts.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("Safe, secure environment")}</div>
              </li>
              <li>
                <img src="/images/clipboard-text-outline.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("English or French tutoring services")}</div>
              </li>
              <li>
                <img src="/images/speech-bubble-round-double-mixed.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("Counselling")}</div>
              </li>
              <li>
                <img src="/images/home.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("Urban and suburban settings")}</div>
              </li>
              <li>
                <img src="/images/action-switches.svg" className="montrealPic" alt="city" />
                <div>{this.props.t("Unique bi-cultural environment")}</div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    );
  }
}

export default withNamespaces()(About);