import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

class Gallery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalStatus: '',
    };
  }

  changeModalStatus = (image) => {
    this.setState({
      modalStatus: image
    })
  }

  render() {
    return (
      <div className="homePageContainer">


        {/*this.state.modalStatus !== "" ?

          <div className="modalWrapper">
            Modal
          <div onClick={() => this.changeModalStatus("")}>X</div>
          </div>

        : ""*/}

        <div className="homeText2">
          <div className="homeTextInnerWrapper">
            <div className="homeTextInner">


            <div className="schoolsCustomButtonsWrapper">
                <Link to="/gallery2" className="SchoolsCumstomButton2" >{this.props.t("Next page")}</Link>
              </div>


       

              <div className="galleryWrapper">
                <div className="galleryWrapperInner">

                  <span onClick={() => this.changeModalStatus("image1")} className="image1"></span>
                  <span onClick={() => this.changeModalStatus("image2")} className="image2"></span>
                  <span onClick={() => this.changeModalStatus("image3")} className="image3"></span>
                  <span onClick={() => this.changeModalStatus("image4")} className="image4"></span>
                  <span onClick={() => this.changeModalStatus("image5")} className="image5"></span>
                  <span onClick={() => this.changeModalStatus("image6")} className="image6"></span>
                  <span onClick={() => this.changeModalStatus("image7")} className="image7"></span>
                  <span onClick={() => this.changeModalStatus("image8")} className="image8"></span>
                  <span onClick={() => this.changeModalStatus("image9")} className="image9"></span>
                  <span onClick={() => this.changeModalStatus("image10")} className="image10"></span>
                  <span onClick={() => this.changeModalStatus("image11")} className="image11"></span>
                  <span onClick={() => this.changeModalStatus("image12")} className="image12"></span>
                  <span onClick={() => this.changeModalStatus("image13")} className="image13"></span>
                  <span onClick={() => this.changeModalStatus("image14")} className="image14"></span>
                  <span onClick={() => this.changeModalStatus("image15")} className="image15"></span>
                  <span onClick={() => this.changeModalStatus("image16")} className="image16"></span>
                  <span onClick={() => this.changeModalStatus("image17")} className="image17"></span>
                  <span onClick={() => this.changeModalStatus("image18")} className="image18"></span>
                  <span onClick={() => this.changeModalStatus("image19")} className="image19"></span>
                  <span onClick={() => this.changeModalStatus("image20")} className="image20"></span>


                </div>
              </div>

              <div className="schoolsCustomButtonsWrapper">
                <Link to="/gallery2" className="SchoolsCumstomButton2" >{this.props.t("Next page")}</Link>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withNamespaces()(Gallery);