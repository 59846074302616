import React from 'react';
import { withNamespaces } from 'react-i18next';

class Centennial extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Centennial Regional High School</h2>
                                <div className="detailsHeader">

                                <div className="subBlock1">
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 76</p>
                                    <p>{this.props.t("Number of students")} 950</p>
                                    <p>{this.props.t("International students")} 1%</p>
                                    <p style={{maxWidth:"400px"}}>{this.props.t("School uniform")} {this.props.t("none – but no torn jeans, or t-shirts with offensive language")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("New gym")} 2009</p>
                                    <p>{this.props.t("Founded")} 1972</p>
                                </div>

                                <div className="subBlock2">
                                <img src="/images/cent1.jpg" alt="city" />
                                <img src="/images/cent2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular academic, Enriched English, Science and Math, Options Drama, Art, Ancient History, Science and the Environment, etc.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, basketball, field hockey, volleyball, badminton,  golf,  x-country running, Curling, track,  American football, etc. Note 40% of students play on a sports team")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Computer Club, Chess Club, Dance, Jazz Band, Drama, Student Radio, Outdoor Education, Trips.")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Centennial);