import React from 'react';
import { withNamespaces } from 'react-i18next';

class Laporte extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>École secondaire Pierre-Laporte</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 78</p>
                                    <p>{this.props.t("Number of students")} 950</p>
                                    <p>{this.props.t("International students")} 0.5%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1974</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/laporte1.jpg" alt="city" />
                                <img src="/images/laporte2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Advanced Literature and Science, Astronomy, Enriched Math and English, Music and Symphony Program – Unique in Montreal.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Basketball, Soccer, Swimming, Volleyball, Competitive Sports, Teams and Capoeira – Brazilian Martial Art.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Amnesty International, Student Council, Radio Club, Student, Newspaper, Graduation Committee, Art Week, Green Committee, Etc.")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Laporte);