import React from 'react';
import { withNamespaces } from 'react-i18next';

class Lester extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Lester B. Pearson High School</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 82</p>
                                    <p>{this.props.t("Number of students")} 1450</p>
                                    <p>{this.props.t("International students")} 3%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")}</p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1975</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/lester2.jpg" alt="city" />
                                <img src="/images/lester1.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Honours Program with Intensive Math, Pre-Calculus, Sports Study Program, Advanced Science Running, Intramural Sports, Etc.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Track and Field, Basketball, Soccer, Cross Country.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Carnival Dances, Variety Show, Hip Hop, Newspaper, Yearbook, Robotics Honor Roll Club, Volunteer Program, Community Involvement.")}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Lester);