import React from 'react';
import { withNamespaces } from 'react-i18next';

const Footer = ({ t }) => (
    <div className="footerWrapper">
        <div className="footerWrapperInner">
  
          <div className="footerElement">
            <h3>{t("Office")}</h3>
            <div>70 Fifth Avenue</div>
            <div>Pointe-Claire, QC</div>
            <div>H9S 5E1, Canada</div>
  
          </div>
  
          <div className="footerElement">
            <h3 className="followUsHeader">{t("Follow us")}</h3>
            <img src="/images/fb.png" alt="city" />
            <img src="/images/vim.png" alt="city" />
            <img src="/images/ins.png" alt="city" />
          </div>
  
          <div className="footerElement">
            <h3>{t("Contact information")}</h3>
            <div>Brian Polan</div>
            <div>{t("Manager")} – International Education</div>
          </div>
  
        </div>
    </div>
  );

  export default withNamespaces()(Footer);