import React from 'react';
import { withNamespaces } from 'react-i18next';

class Labelle extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>École secondaire Curé-Antoine-Labelle</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 150</p>
                                    <p>{this.props.t("Number of students")} 2500</p>
                                    <p>{this.props.t("International students")} 0.5%</p>
                                    <p>{this.props.t("Grades")} {this.props.t("9-11")}</p>
                                    <p>{this.props.t("Founded")}{this.props.t("1963 (Expanded in 1975)")}</p>
                                    <p>{this.props.t("School uniform")}{this.props.t("required – top only")}</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/Antoine2.jpg" alt="city" />
                                <img src="/images/Antoine.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular Academic, Concentration in Art, Drama Program, Music Symphony Program, Multi Media, Program and Sports-study Program.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, Basketball, Badminton, Volleyball, Ski , Rock Climbing, American Football, Intramural Sports, Etc.")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Amnesty International, Community Involvement, Action-environment, Stand-up Comic Club, Ski Club, Student Council, Travel Club – New York, Boston, Grad Ball Committee, Etc.")}</span>
                                </div>
 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Labelle);