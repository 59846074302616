import React from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

class GalleryTwo extends React.Component {
  render() {
    return (
      <div className="homePageContainer">
        <div className="homeText2">
          <div className="homeTextInnerWrapper">
            <div className="homeTextInner">

            <div className="schoolsCustomButtonsWrapper">
                <Link to="/gallery" className="SchoolsCumstomButton2" >{this.props.t("Previous page")}</Link>
              </div>

        

              <div className="galleryWrapper">
                <div className="galleryWrapperInner">
               
                  <span className="image21"></span>
                  <span className="image22"></span>
                  <span className="image23"></span>
                  <span className="image24"></span>
                  <span className="image25"></span>
                  <span className="image26"></span>
                  <span className="image27"></span>
                  <span className="image28"></span>
                  <span className="image29"></span>
                  <span className="image30"></span>
                  <span className="image31"></span>
                  <span className="image32"></span>
                  <span className="image33"></span>
                  <span className="image34"></span>
                  <span className="image35"></span>
                  <span className="image36"></span>
                  <span className="image37"></span>
                  <span className="image38"></span>
                  <span className="image39"></span>
                  <span className="image40"></span>


                </div>
              </div>

              <div className="schoolsCustomButtonsWrapper">
                <Link to="/gallery" className="SchoolsCumstomButton2" >{this.props.t("Previous page")}</Link>
              </div>

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withNamespaces()(GalleryTwo);