import React from 'react';
import { withNamespaces } from 'react-i18next';

class Anne extends React.Component {
    render() {
        return (
            <div className="homePageContainer">
                <div className="homeText">
                    <div className="homeTextInnerWrapper">
                        <div className="homeTextInner">
                            <div className="homeTextActualText">
                            <h2>Collège Sainte-Anne</h2>
                                <div className="detailsHeader">
                                <div className="subBlock1">
                                    
                                    <h3>{this.props.t("Statistics")}</h3>
                                    <p>{this.props.t("Number of teachers")} 87</p>
                                    <p>{this.props.t("Number of students")} 1650</p>
                                    <p>{this.props.t("International students")} 0.5%</p>
                                    <p>{this.props.t("School uniform")} {this.props.t("Required")} </p>
                                    <p>{this.props.t("Grades")} {this.props.t("7-11")}</p>
                                    <p>{this.props.t("Founded")} 1965</p>
                                </div>
                                <div className="subBlock2">
                                <img src="/images/anne1.jpg" alt="city" />
                                <img src="/images/anne2.jpg" alt="city" />
                                </div>
                                </div>

                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Programs")}</h2>
                                    <span>{this.props.t("Regular academic, Dance concentration (ballet and modern) program, Music concentration program  (wind instruments), sports–study program")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Sports Activities")}</h2>
                                    <span>{this.props.t("Soccer, Basketball, Badminton, Volleyball, Football, Intramural sports, etc")}</span>
                                </div>
                                <div className="schoolDetailsBlock">
                                    <h2>{this.props.t("Club Activities")}</h2>
                                    <span>{this.props.t("Amnesty International, Community involvement, Action-environment,  jazz band,  science club , robotics team,  student council, travel club and more!")}</span>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNamespaces()(Anne);